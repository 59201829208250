import React from "react"

import Layout from "../components/layout"
import AboutusPage from "../components/aboutus/index"
import Seo from "../components/seo"

const aboutusPage = () => (
  <Layout>
    <Seo title="About us - Mass Media Creatives" desc="" keywords="" />
    <AboutusPage />
  </Layout>
)

export default aboutusPage
