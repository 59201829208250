import React from "react"
import about from "../../images/showcase/image_09.png"

const aboutpage = () => {
  return (
    <>
      <section className="header-about design-header bg-darker pb-0 text-center py-2 mb-0 about-slider">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-7 mt-2 mt-lg-0">
              <ul className="text-start  list-unstyled mb-0  ">
                <li className="d-flex">
                  <div className="ml-4">
                    <h2 className="title mb-2 slider-hero">About us</h2>
                    <h3 className="title mb-2 about-slider">
                      {" "}
                      Bespoke{" "}
                      <span className="fw-bold">
                        Digital &amp; Print Media{" "}
                      </span>
                      <br />
                      solutions for a successful brand
                    </h3>
                  </div>
                </li>
              </ul>
            </div>

            <div className="d-none d-lg-block col-lg-5">
              <div className="text-center ml-0 ml-xl-5">
                <img src={about} alt="about-slider" className="img-fluid"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default aboutpage
