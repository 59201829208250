import React from "react"
import years from "../../images/38.png"

const experiencepage = () => {
  return (
    <>
      <section>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-6  image-flip">
              <img className="sample-image" src={years} alt="customers " />
              <p className="digital">
                Digital branding <br />
                &amp; printing
              </p>

              <div id="timeline" className="py-4">
                <ol>
                  <li>
                    <span className="point"></span>
                    <h6 className="diplome">1986</h6>
                    <p className="timestamp">Chitralaya Arts</p>
                  </li>
                  <li>
                    <span className="point"></span>
                    <h6 className="diplome">2010</h6>
                    <p className="timestamp">Mass Media</p>
                  </li>
                  <li className="dashed-point">
                    <span className="point "></span>
                    <h6 className="diplome">2019</h6>
                    <p className="timestamp">Mass Media Creatives</p>
                  </li>
                </ol>
              </div>
            </div>
            <div className="col-12 col-lg-6 ">
              <div className="section-header">
                <span
                  className="slider-hero"
                  data-animation="fadeInLeft"
                  data-delay="0.1s"
                >
                  wHO WE ARE
                </span>
                <p>
                  3 decades <br /> of experience
                </p>
              </div>
              <div className="package started-text">
                <p>
                  Started with a vision of establishing business brands
                  <br />
                  and driven with the thought of innovation.
                </p>
                <p className="story-text">
                  This story goes back in 1986 when we registered ‘Chitralaya
                  Arts’ the word ‘Chitra’ means pictures. We are equipped with
                  over 34 years of experience and expertise in offering print
                  media and digital media solutions to our customers. With some
                  more improvisations to our digital media sphere, we renamed
                  our company to MASS MEDIA CREATIVES in the year 2019. We
                  re-defined our business goal with the vision of catering to a
                  wider audience across the globe and across all industries.
                </p>
                <p className="story-text">
                  Mass Media Creatives works in close connection with client
                  requirements for enriching the brand promotion campaign with
                  innovative digital media creatives.It guides our investment in
                  modern technology and helps us to works on a constantly
                  envolving pattern.Our growth is streamlined with the growth of
                  our customers. We are to serve our customers with the best
                  creatives solution with our highly imaginative and passionate
                  team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default experiencepage
