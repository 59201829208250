import React from "react"
import Slider from "./slider"
import Creatives from "./creatives"
import Services from "../home/services"
import Experience from "./experience"
import Vision from "./vision"
import Clients from "../home/clients"

const homePage = () => {
  return (
    <>
      <Slider />
      <Creatives />
      <Experience />
      <Vision />
      <Services />

      <Clients />
    </>
  )
}

export default homePage
