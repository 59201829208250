import React from "react"

const visionpage = () => {
  return (
    <>
      <section className="vision">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <h1 className="page-title vision">VISION</h1>
              <p className="vision-title">
                Helping businesses by growing their brands to increase reach,
                revenue and reputation.
              </p>
              <p className="vision-subtitle">
                Branding is crucial for companies to win the game. Our team
                focuses on creating an impact in the world by the brands we
                build.
              </p>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block"></div>
            <div className="col-12 col-lg-5">
              <h1 className="page-title vision">VALUES</h1>
              <p className="vision-title">
                Brands are dynamic, so are we. Brands evolve fast, so does the
                customers.
              </p>
              <p className="vision-subtitle">
                Only companies who adapt to this delicate balance can survive.
                If your brand cannot stay ahead of the game, you risk fading
                away without an impact.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default visionpage
