import React from "react"

const creativespage = () => {
  return (
    <section>
      <div className="container pt-4">
        <div className="row">
          <div className="col-12 col-lg-12 text-center">
            <h4 className="heading  about-heading">MASS MEDIA CREATIVES</h4>
            <p className="story-text py-4">
              We believe in enriching brands through our ingenious digital
              branding and printing services across various categories of
              brands. We offer bespoke branding solutions with the unique
              combination of technology and creativity at scale. We commit on
              complete operational transparency to ensure that every step taken
              by us is streamlined with the client’s vision.
            </p>
            <p className="about-digital py-4">
              We construct digital art around your brand strategy that helps in
              providing a competitive edge and portrays the uniqueness of your
              brand
            </p>
            <p className="story-text py-4">
              Our success is based on understanding the needs and expectations
              of the brand we are working with and delivering the finest digital
              solutions. We believe that every customer is important to us and
              we are keen on getting to know more about you so we can tell your
              story well. Customization is the key and our solutions are based
              on highlighting the most unique aspects of your brand. We are a
              talented bunch of people with creative minds and we are always
              in-tune with the latest market trends. Our team is experienced and
              is capable of providing the finest business insights that earns us
              the trust of our clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default creativespage
